.l-header {
  @include container;
  position: relative;
  height: 120px;
  z-index: 1;
  @include mqSP {
    background: #fff;
    height: 60px;
    border-top: 4px solid #000;
  }
}
.header-logo {
  @include texthidden;
  position: absolute;
  left: 14px;
  top: 0;
  width: 76px;
  height: 100%;
  a {
    display: block;
    width: 76px;
    height: 100%;
    background-image: url(../img/logo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
  }
  @include mqSP {
    a {
      width: 38px;
    }
  }
}
.header-sp-nav {
  @include mqSP {
    display: none;
    position: absolute;
    background: rgba(0,0,0, .8);
    width: 100%;
    left: 0;
    top: 56px;
    z-index: 9999;
    padding: 30px 15px;

  }
}
.header-nav {
  font-size: 16px;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 15px;
  ul {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    li {
      margin: 0 25px;
      padding: 0 0 5px 0;
      &.is-current {
        border-bottom: 2px solid #fff;
      }
      a {
        color: #fff;
      }
    }
  }
  @include mqSP {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    ul {
      display: block;
      li {
        padding: 0;
        margin: 0 0 25px 0;
        font-size: 15px;
        &.is-current {
          border: none;
        }
      }
    }
  }
}
.header-lang {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-end;
  width: 630px;
  .lang-en & {
    width: 530px;
  }
  a {
    color: #999;
    font-size: 12px;
    &:after {
      content: " ｜ ";
      display: inline-block;
      color: #fff;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &.is-current {
      color: #fff;
    }
  }
  @include mqSP {
    position: relative;
    justify-content: initial;
    transform: none;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.header-nav-trigger {
  display: none;
  @include mqSP {
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate3d(-50%,-50%,0);
    a {
      @include texthidden;
      display: block;
      width: 30px;
      height: 32px;
      background-image: url(../img/menu-open.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
    }
  }
  .is-open & {
    @include mqSP {
      a {
        background-image: url(../img/menu-close.png);
      }
    }
  }
}


.icon-facebook {
  @include texthidden;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon-facebook.png);
  background-size: 20px 20px;
}