.about-header,
.about-message {
  padding-bottom: 120px;
  h1,p,img {
    @include container;
    text-align: center;
  }
  h1 {
    font-size: 32px;
    margin-bottom: 30px;
  }
  p {
    font-size: 16px;
    line-height: 1.6;
  }
  img {
    display: block;
    margin-top: 65px;
  }
  @include mqSP {
    padding-bottom: 80px;
    h1 {
      font-size: 24px;
      line-height: 1.6;
      margin-bottom: 40px;
    }
    p {
      font-size: 14px;
      line-height: 1.8;
      text-align: left;
    }
    img {
      margin-top: 30px;
    }
  }
}
.about-message {
  padding-bottom: 0;
}
.about-history {
  background-image: url(../img/about-bg.png);
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding-bottom: 100px;
  h1 {
    @include container;
    font-size: 32px;
    text-align: center;
    margin-bottom: 50px;
  }
  dl {
    margin: 0 auto 0 auto;
    display: flex;
    width: 850px;
    line-height: 1.5;
    dt,dd {
      width: 100%;
      margin: 0 0 20px 0;
      padding: 0 0 20px 0;
    }
    dt {
      border-bottom: 1px solid #000;
      width: 150px;
      margin-right: 30px;
      padding-left: 10px;
    }
    dd {
      border-bottom: 1px dashed #000;
      flex: 1;
      width: 100%;
      padding-left: 10px;
    }
    &:last-child {
      margin-bottom: 0;
      padding: 0;
    }
  }
  @include mqSP {
    padding-bottom: 50px;
    background-repeat: repeat-y;
    h1 {
      font-size: 21px;
      margin-bottom: 40px;
    }
    dl {
      width: calc(100% - 30px);
      margin: 0 auto;
      display: block;
      font-size: 14px;
      dt {
        display: inline;
        padding: 5px 10px;
        border-bottom: none;
        font-weight: bold;
        font-size: 16px;
      }
      dd {
        margin-top: 10px;
        width: 100%;
        padding: 0 10px 10px 10px;
        margin-bottom: 15px;
      }
      &:last-child {
        margin-bottom: 0;
        padding: 0;
      }
    }
  }
}
.about-history-inner {
  margin-bottom: 120px;
  &:last-child {
    margin-bottom: 0;
  }
  @include mqSP {
    margin-bottom: 100px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}