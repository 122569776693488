%hero {
  margin-top: -120px;
  padding-top: 120px;
  box-sizing: content-box;
  @include mqSP {
    padding-top: 0;
    margin-top: 0;
  }
}

.top-hero {
  @extend %hero;
  position: relative;
  z-index: 0;
  width: 100%;
  height: calc(100vh - 120px);
  // height: 100%;
  overflow: hidden;
  video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    min-height: 100%;
    min-height: 100vh;
    min-width: 100%;
    // min-width: 100vw;
  }
  @include mqSP {
    background-image: url(../img/top-hero_sp.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    video {
      display: none;
    }
  }
}
.top-hero-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  @include container;
  z-index: 1;
  color: #fff;
  h1 {
    position: relative;
    font-size: 48px;
    margin-bottom: 30px;
    // overflow: hidden;
    p {
      display: none;
    }
    span {
      display: inline-block;
      opacity: 0;
      transform: translateY(20px);
      transition: all .5s ease;
      &.is-show {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  > p {
    font-size: 18px;
    // font-family: $f_go;
    line-height: 1.6;
    text-align: left;
    span {
      display: block;
      opacity: 0;
      transition: all .8s ease;
      transform: translate3d(20px,0,0);
      &.is-show {
        transform: translate3d(0,0,0);
        opacity: 1;
      }
    }
  }

  @include mqSP {
    padding: 0 15px;
    text-align: center;
    h1 {
      font-size: 25px;
    }
    > p {
      line-height: 1.5;
      font-size: 14px;
      span {
        transform: translate3d(0,15px,0);
      }
    }
  }
}
.top-hero-scroll {
  @include texthidden;
  width: 45px;
  height: 66px;
  background-image: url(../img/icon-scroll.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translate3d(-50%,0,0);
}

.about-hero,
.contact-hero,
.information-hero,
.shop-hero {
  @extend %hero;
  height: 360px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  h1 {
    @include container;
    padding: 210px 0 0 0;
    color: #fff;
    font-size: 38px;
    text-align: center;
  }
  @include mqSP {
    height: 180px;
    h1 {
      padding-top: 120px;
      font-size: 25px;
    }
  }
}


.about-hero {
  background-image: url(../img/about-hero.png);
}
.contact-hero {
  background-image: url(../img/contact-hero.png);
}
.information-hero {
  background-image: url(../img/information-hero.png);
}
.shop-hero {
  background-image: url(../img/shop-hero.png);
}
