.contact {
  padding-bottom: 100px;
  @include mqSP {
    padding-bottom: 50px;
  }
}
.contact-text {
  @include container;
  text-align: center;
  font-size: 16px;
  margin-bottom: 60px;
  line-height: 1.5;
  @include mqSP {
    font-size: 14px;
    margin-bottom: 40px;
  }
}

.contact-inner {
  @include container;
  max-width: 932px;
  margin: 0 auto 50px auto;
  border: 1px solid #000;
  padding: 70px 0;
  h2 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 40px;
  }
  p {
    font-size: 16px;
    line-height: 1.625;
    text-align: center;
    margin-bottom: 50px;
    small {
      font-size: 14px;
      display: block;
    }
  }
  @include mqSP {
    width: calc(100% - 30px);
    margin: 0 auto 40px auto;
    padding: 20px 15px;
    h2 {
      font-size: 21px;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 20px;
    }
    p:not(.btn):not(.btn_border) {
      text-align: left;
    }
  }
}

.form-item {
  width: 700px;
  margin: 0 auto 30px auto;
  display: flex;
  p {
    font-size: 18px;
    width: 200px;
    margin: 0;
    text-align: left;
  }
  input,
  textarea {
    width: 500px;
    &:focus {
      background: #f7f7f7;
      outline: none;
      border: 1px solid #ccc;
      box-shadow: 0px 0px 6px rgba(0,0,0,.2);
    }
    &.is-error {
      background-color: #fff7f8;
      background-image: url(../img/icon_error.png);
      background-repeat: no-repeat;
      background-size: 18px auto;
      background-position: 98% center;
      border: 1px solid #cd2929;
    }



  }
  input {
    height: 42px;
    line-height: 42px;
    padding: 0 10px;
  }
  textarea {
    padding: 10px;
    border: 1px solid #ccc;
    height: 240px;
  }
  @include mqSP {
    width: 100%;
    padding: 0 15px;
    margin: 0 auto 15px auto;
    display: block;
    p {
      font-size: 14px;
      width: 100%;
      margin: 0 0 10px 0;
    }
    input,
    textarea {
      width: 100%;
      border: 1px solid #ccc;
    }
    input {
      // height: 20px;
      // line-height: 20px;
      // padding: 0 10px;
    }
    textarea {
      padding: 10px;
      height: 200px;
    }
  }
}