.breadcrumb {
  @include container;
  font-size: 14px;
  padding: 20px 0;
  margin-bottom: 70px;
  ul {
    display: flex;
    li {
      &:after {
        content: "　>　";
        display: inline-block;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
  a {
    color: #000;
  }
  @include mqSP {
    padding: 10px 15px;
    margin-bottom: 35px;
  }
}