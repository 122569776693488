.pagetop {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 70px;
  height: 70px;
  a {
    display: block;
    @include texthidden;
    width: 70px;
    height: 70px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url(../img/pagetop.png);
  }
  @include mqSP {
    width: 45px;
    height: 45px;
    right: 15px;
    bottom: 15px;
    a {
      width: 45px;
      height: 45px;
    }
  }
}