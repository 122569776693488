@charset "UTF-8";
@-webkit-keyframes animation {
  100% {
    opacity: 1; } }

@keyframes animation {
  100% {
    opacity: 1; } }

@-webkit-keyframes animation2 {
  100% {
    opacity: 1;
    top: 0; } }

@keyframes animation2 {
  100% {
    opacity: 1;
    top: 0; } }

@-webkit-keyframes animation3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.1); } }

@keyframes animation3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.1); } }

/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  /*  font-weight: normal;*/
  vertical-align: baseline;
  background: transparent;
  font-weight: normal; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ul, ol {
  list-style: none; }

a {
  margin: 0;
  padding: 0;
  outline: none;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input, select {
  vertical-align: middle; }

input[type="submit"],
input[type="reset"],
button,
select {
  cursor: pointer;
  -webkit-appearance: none;
  outline: none; }

img {
  vertical-align: bottom;
  outline: none;
  outline: 0; }

button {
  border: none;
  outline: none;
  margin: 0;
  padding: 0; }

address {
  font-style: normal; }

/*---------------------------------------------------------*/
/* ベース */
/*---------------------------------------------------------*/
html, body {
  width: 100%;
  height: 100%; }

html {
  font-size: 62.5%; }

body {
  position: relative;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  color: #000;
  font-size: 14px; }
  body.is-fixed {
    position: fixed; }

* {
  box-sizing: border-box; }

a {
  color: inherit;
  text-decoration: none;
  transition: all .2s ease; }
  a:hover {
    text-decoration: none;
    opacity: 0.7; }

img {
  vertical-align: bottom; }

small {
  font-size: 11px;
  font-size: 1.1rem; }

.breadcrumb {
  width: 100%;
  max-width: 1072px;
  margin: 0 auto;
  font-size: 14px;
  padding: 20px 0;
  margin-bottom: 70px; }
  @media screen and (max-width: 767px) {
    .breadcrumb {
      padding: 0 15px;
      max-width: 100%; } }
  .breadcrumb ul {
    display: flex; }
    .breadcrumb ul li:after {
      content: "　>　";
      display: inline-block; }
    .breadcrumb ul li:last-child:after {
      display: none; }
  .breadcrumb a {
    color: #000; }
  @media screen and (max-width: 767px) {
    .breadcrumb {
      padding: 10px 15px;
      margin-bottom: 35px; } }

.btn, .btn_border {
  position: relative; }
  .btn a, .btn_border a, .btn span, .btn_border span {
    position: relative;
    display: inline-block;
    color: #fff;
    width: 280px;
    height: 54px;
    line-height: 52px;
    font-size: 18px;
    background: #000;
    text-align: center;
    padding-right: 10px; }
    .btn a:after, .btn_border a:after, .btn span:after, .btn_border span:after {
      content: "";
      position: absolute;
      right: 20px;
      top: 0;
      width: 9px;
      height: 100%;
      background: url(../img/arrow.png);
      background-size: 9px 12px;
      background-position: center;
      background-repeat: no-repeat; }
  .btn input, .btn_border input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }
  .top-about .btn, .top-about .btn_border {
    margin-top: 50px; }
  .top-information .btn, .top-information .btn_border {
    margin-top: 40px;
    text-align: center; }
  .l-footer .btn, .l-footer .btn_border {
    position: absolute;
    right: 0;
    top: 0; }
    .l-footer .btn a, .l-footer .btn_border a {
      font-size: 16px;
      width: 220px;
      height: 48px;
      line-height: 46px; }
  @media screen and (max-width: 767px) {
    .btn a, .btn_border a, .btn span, .btn_border span {
      font-size: 12px;
      width: 180px;
      height: 38px;
      line-height: 36px; }
    .top-about .btn, .top-about .btn_border {
      margin-top: 40px; }
    .l-footer .btn, .l-footer .btn_border {
      position: relative;
      text-align: center;
      margin: 25px 0 35px 0; }
      .l-footer .btn a, .l-footer .btn_border a {
        font-size: 12px;
        width: 180px;
        height: 38px;
        line-height: 36px; } }

.btn_border a {
  border: 1px solid #fff; }

.l-footer {
  background: #000;
  color: #fff;
  padding: 25px 0 60px 0; }
  @media screen and (max-width: 767px) {
    .l-footer {
      padding: 25px 0; } }

.footer-logo {
  display: inline-block;
  font-size: 0;
  overflow: hidden;
  vertical-align: middle;
  white-space: nowrap;
  text-indent: 100%;
  width: 67px;
  height: 80px; }
  .footer-logo a {
    display: block;
    width: 67px;
    height: 80px;
    background-image: url(../img/logo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%; }
  @media screen and (max-width: 767px) {
    .footer-logo {
      position: absolute;
      left: 15px;
      top: 0;
      width: 34px;
      height: 40px; }
      .footer-logo a {
        width: 34px;
        height: 40px; } }

.footer-inner {
  width: 100%;
  max-width: 1072px;
  margin: 0 auto;
  position: relative;
  display: flex; }
  @media screen and (max-width: 767px) {
    .footer-inner {
      padding: 0 15px;
      max-width: 100%; } }
  @media screen and (max-width: 767px) {
    .footer-inner {
      display: block;
      padding: 0; } }

.footer-sp-inner {
  display: flex; }
  @media screen and (max-width: 767px) {
    .footer-sp-inner {
      display: block;
      margin: 0 0 0 60px; } }

.footer-name {
  font-size: 22px;
  margin: 10px 30px 0 30px; }
  @media screen and (max-width: 767px) {
    .footer-name {
      margin: 0 0 10px 0;
      font-size: 16px; } }

.footer-address {
  font-size: 16px;
  line-height: 1.37;
  margin: 10px 0 0 0; }
  @media screen and (max-width: 767px) {
    .footer-address {
      font-size: 12px; } }

.footer-copy {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-size: 14px;
  text-align: right; }
  @media screen and (max-width: 767px) {
    .footer-copy {
      position: relative;
      text-align: center;
      border-top: 1px solid #fff;
      font-size: 9px;
      padding: 15px 0; } }

.l-header {
  width: 100%;
  max-width: 1072px;
  margin: 0 auto;
  position: relative;
  height: 120px;
  z-index: 1; }
  @media screen and (max-width: 767px) {
    .l-header {
      padding: 0 15px;
      max-width: 100%; } }
  @media screen and (max-width: 767px) {
    .l-header {
      background: #fff;
      height: 60px;
      border-top: 4px solid #000; } }

.header-logo {
  display: inline-block;
  font-size: 0;
  overflow: hidden;
  vertical-align: middle;
  white-space: nowrap;
  text-indent: 100%;
  position: absolute;
  left: 14px;
  top: 0;
  width: 76px;
  height: 100%; }
  .header-logo a {
    display: block;
    width: 76px;
    height: 100%;
    background-image: url(../img/logo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto; }
  @media screen and (max-width: 767px) {
    .header-logo a {
      width: 38px; } }

@media screen and (max-width: 767px) {
  .header-sp-nav {
    display: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    left: 0;
    top: 56px;
    z-index: 9999;
    padding: 30px 15px; } }

.header-nav {
  font-size: 16px;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 15px; }
  .header-nav ul {
    display: flex;
    justify-content: center;
    align-items: flex-end; }
    .header-nav ul li {
      margin: 0 25px;
      padding: 0 0 5px 0; }
      .header-nav ul li.is-current {
        border-bottom: 2px solid #fff; }
      .header-nav ul li a {
        color: #fff; }
  @media screen and (max-width: 767px) {
    .header-nav {
      position: relative;
      top: 0;
      left: 0;
      transform: none; }
      .header-nav ul {
        display: block; }
        .header-nav ul li {
          padding: 0;
          margin: 0 0 25px 0;
          font-size: 15px; }
          .header-nav ul li.is-current {
            border: none; } }

.header-lang {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-end;
  width: 630px; }
  .lang-en .header-lang {
    width: 530px; }
  .header-lang a {
    color: #999;
    font-size: 12px; }
    .header-lang a:after {
      content: " ｜ ";
      display: inline-block;
      color: #fff; }
    .header-lang a:last-child:after {
      display: none; }
    .header-lang a.is-current {
      color: #fff; }
  @media screen and (max-width: 767px) {
    .header-lang {
      position: relative;
      justify-content: initial;
      transform: none;
      top: 0;
      left: 0;
      width: 100%; } }

.header-nav-trigger {
  display: none; }
  @media screen and (max-width: 767px) {
    .header-nav-trigger {
      display: block;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translate3d(-50%, -50%, 0); }
      .header-nav-trigger a {
        display: inline-block;
        font-size: 0;
        overflow: hidden;
        vertical-align: middle;
        white-space: nowrap;
        text-indent: 100%;
        display: block;
        width: 30px;
        height: 32px;
        background-image: url(../img/menu-open.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 100%; } }
  @media screen and (max-width: 767px) {
    .is-open .header-nav-trigger a {
      background-image: url(../img/menu-close.png); } }

.icon-facebook {
  display: inline-block;
  font-size: 0;
  overflow: hidden;
  vertical-align: middle;
  white-space: nowrap;
  text-indent: 100%;
  width: 20px;
  height: 20px;
  background-image: url(../img/icon-facebook.png);
  background-size: 20px 20px; }

.top-hero, .about-hero,
.contact-hero,
.information-hero,
.shop-hero {
  margin-top: -120px;
  padding-top: 120px;
  box-sizing: content-box; }
  @media screen and (max-width: 767px) {
    .top-hero, .about-hero,
    .contact-hero,
    .information-hero,
    .shop-hero {
      padding-top: 0;
      margin-top: 0; } }

.top-hero {
  position: relative;
  z-index: 0;
  width: 100%;
  height: calc(100vh - 120px);
  overflow: hidden; }
  .top-hero video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    min-height: 100%;
    min-height: 100vh;
    min-width: 100%; }
  @media screen and (max-width: 767px) {
    .top-hero {
      background-image: url(../img/top-hero_sp.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
      .top-hero video {
        display: none; } }

.top-hero-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
  max-width: 1072px;
  margin: 0 auto;
  z-index: 1;
  color: #fff; }
  @media screen and (max-width: 767px) {
    .top-hero-inner {
      padding: 0 15px;
      max-width: 100%; } }
  .top-hero-inner h1 {
    position: relative;
    font-size: 48px;
    margin-bottom: 30px; }
    .top-hero-inner h1 p {
      display: none; }
    .top-hero-inner h1 span {
      display: inline-block;
      opacity: 0;
      transform: translateY(20px);
      transition: all .5s ease; }
      .top-hero-inner h1 span.is-show {
        transform: translateY(0);
        opacity: 1; }
  .top-hero-inner > p {
    font-size: 18px;
    line-height: 1.6;
    text-align: left; }
    .top-hero-inner > p span {
      display: block;
      opacity: 0;
      transition: all .8s ease;
      transform: translate3d(20px, 0, 0); }
      .top-hero-inner > p span.is-show {
        transform: translate3d(0, 0, 0);
        opacity: 1; }
  @media screen and (max-width: 767px) {
    .top-hero-inner {
      padding: 0 15px;
      text-align: center; }
      .top-hero-inner h1 {
        font-size: 25px; }
      .top-hero-inner > p {
        line-height: 1.5;
        font-size: 14px; }
        .top-hero-inner > p span {
          transform: translate3d(0, 15px, 0); } }

.top-hero-scroll {
  display: inline-block;
  font-size: 0;
  overflow: hidden;
  vertical-align: middle;
  white-space: nowrap;
  text-indent: 100%;
  width: 45px;
  height: 66px;
  background-image: url(../img/icon-scroll.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translate3d(-50%, 0, 0); }

.about-hero,
.contact-hero,
.information-hero,
.shop-hero {
  height: 360px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .about-hero h1,
  .contact-hero h1,
  .information-hero h1,
  .shop-hero h1 {
    width: 100%;
    max-width: 1072px;
    margin: 0 auto;
    padding: 210px 0 0 0;
    color: #fff;
    font-size: 38px;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .about-hero h1,
      .contact-hero h1,
      .information-hero h1,
      .shop-hero h1 {
        padding: 0 15px;
        max-width: 100%; } }
  @media screen and (max-width: 767px) {
    .about-hero,
    .contact-hero,
    .information-hero,
    .shop-hero {
      height: 180px; }
      .about-hero h1,
      .contact-hero h1,
      .information-hero h1,
      .shop-hero h1 {
        padding-top: 120px;
        font-size: 25px; } }

.about-hero {
  background-image: url(../img/about-hero.png); }

.contact-hero {
  background-image: url(../img/contact-hero.png); }

.information-hero {
  background-image: url(../img/information-hero.png); }

.shop-hero {
  background-image: url(../img/shop-hero.png); }

.pagetop {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 70px;
  height: 70px; }
  .pagetop a {
    display: block;
    display: inline-block;
    font-size: 0;
    overflow: hidden;
    vertical-align: middle;
    white-space: nowrap;
    text-indent: 100%;
    width: 70px;
    height: 70px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url(../img/pagetop.png); }
  @media screen and (max-width: 767px) {
    .pagetop {
      width: 45px;
      height: 45px;
      right: 15px;
      bottom: 15px; }
      .pagetop a {
        width: 45px;
        height: 45px; } }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.notfound {
  width: 100%;
  max-width: 1072px;
  margin: 0 auto;
  padding-bottom: 100px; }
  @media screen and (max-width: 767px) {
    .notfound {
      padding: 0 15px;
      max-width: 100%; } }
  .notfound h1 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 30px; }
  .notfound p {
    font-size: 16px;
    text-align: center;
    margin-bottom: 40px;
    line-height: 1.5; }
    .notfound p:last-child {
      margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .notfound {
      padding: 0 15px 50px 15px; }
      .notfound h1 {
        font-size: 21px; } }

.about-header,
.about-message {
  padding-bottom: 120px; }
  .about-header h1, .about-header p, .about-header img,
  .about-message h1,
  .about-message p,
  .about-message img {
    width: 100%;
    max-width: 1072px;
    margin: 0 auto;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .about-header h1, .about-header p, .about-header img,
      .about-message h1,
      .about-message p,
      .about-message img {
        padding: 0 15px;
        max-width: 100%; } }
  .about-header h1,
  .about-message h1 {
    font-size: 32px;
    margin-bottom: 30px; }
  .about-header p,
  .about-message p {
    font-size: 16px;
    line-height: 1.6; }
  .about-header img,
  .about-message img {
    display: block;
    margin-top: 65px; }
  @media screen and (max-width: 767px) {
    .about-header,
    .about-message {
      padding-bottom: 80px; }
      .about-header h1,
      .about-message h1 {
        font-size: 24px;
        line-height: 1.6;
        margin-bottom: 40px; }
      .about-header p,
      .about-message p {
        font-size: 14px;
        line-height: 1.8;
        text-align: left; }
      .about-header img,
      .about-message img {
        margin-top: 30px; } }

.about-message {
  padding-bottom: 0; }

.about-history {
  background-image: url(../img/about-bg.png);
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding-bottom: 100px; }
  .about-history h1 {
    width: 100%;
    max-width: 1072px;
    margin: 0 auto;
    font-size: 32px;
    text-align: center;
    margin-bottom: 50px; }
    @media screen and (max-width: 767px) {
      .about-history h1 {
        padding: 0 15px;
        max-width: 100%; } }
  .about-history dl {
    margin: 0 auto 0 auto;
    display: flex;
    width: 850px;
    line-height: 1.5; }
    .about-history dl dt, .about-history dl dd {
      width: 100%;
      margin: 0 0 20px 0;
      padding: 0 0 20px 0; }
    .about-history dl dt {
      border-bottom: 1px solid #000;
      width: 150px;
      margin-right: 30px;
      padding-left: 10px; }
    .about-history dl dd {
      border-bottom: 1px dashed #000;
      flex: 1;
      width: 100%;
      padding-left: 10px; }
    .about-history dl:last-child {
      margin-bottom: 0;
      padding: 0; }
  @media screen and (max-width: 767px) {
    .about-history {
      padding-bottom: 50px;
      background-repeat: repeat-y; }
      .about-history h1 {
        font-size: 21px;
        margin-bottom: 40px; }
      .about-history dl {
        width: calc(100% - 30px);
        margin: 0 auto;
        display: block;
        font-size: 14px; }
        .about-history dl dt {
          display: inline;
          padding: 5px 10px;
          border-bottom: none;
          font-weight: bold;
          font-size: 16px; }
        .about-history dl dd {
          margin-top: 10px;
          width: 100%;
          padding: 0 10px 10px 10px;
          margin-bottom: 15px; }
        .about-history dl:last-child {
          margin-bottom: 0;
          padding: 0; } }

.about-history-inner {
  margin-bottom: 120px; }
  .about-history-inner:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .about-history-inner {
      margin-bottom: 100px; }
      .about-history-inner:last-child {
        margin-bottom: 0; } }

.contact {
  padding-bottom: 100px; }
  @media screen and (max-width: 767px) {
    .contact {
      padding-bottom: 50px; } }

.contact-text {
  width: 100%;
  max-width: 1072px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  margin-bottom: 60px;
  line-height: 1.5; }
  @media screen and (max-width: 767px) {
    .contact-text {
      padding: 0 15px;
      max-width: 100%; } }
  @media screen and (max-width: 767px) {
    .contact-text {
      font-size: 14px;
      margin-bottom: 40px; } }

.contact-inner {
  width: 100%;
  max-width: 1072px;
  margin: 0 auto;
  max-width: 932px;
  margin: 0 auto 50px auto;
  border: 1px solid #000;
  padding: 70px 0; }
  @media screen and (max-width: 767px) {
    .contact-inner {
      padding: 0 15px;
      max-width: 100%; } }
  .contact-inner h2 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 40px; }
  .contact-inner p {
    font-size: 16px;
    line-height: 1.625;
    text-align: center;
    margin-bottom: 50px; }
    .contact-inner p small {
      font-size: 14px;
      display: block; }
  @media screen and (max-width: 767px) {
    .contact-inner {
      width: calc(100% - 30px);
      margin: 0 auto 40px auto;
      padding: 20px 15px; }
      .contact-inner h2 {
        font-size: 21px;
        margin-bottom: 20px; }
      .contact-inner p {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 20px; }
      .contact-inner p:not(.btn):not(.btn_border):not(.btn_border) {
        text-align: left; } }

.form-item {
  width: 700px;
  margin: 0 auto 30px auto;
  display: flex; }
  .form-item p {
    font-size: 18px;
    width: 200px;
    margin: 0;
    text-align: left; }
  .form-item input,
  .form-item textarea {
    width: 500px; }
    .form-item input:focus,
    .form-item textarea:focus {
      background: #f7f7f7;
      outline: none;
      border: 1px solid #ccc;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2); }
    .form-item input.is-error,
    .form-item textarea.is-error {
      background-color: #fff7f8;
      background-image: url(../img/icon_error.png);
      background-repeat: no-repeat;
      background-size: 18px auto;
      background-position: 98% center;
      border: 1px solid #cd2929; }
  .form-item input {
    height: 42px;
    line-height: 42px;
    padding: 0 10px; }
  .form-item textarea {
    padding: 10px;
    border: 1px solid #ccc;
    height: 240px; }
  @media screen and (max-width: 767px) {
    .form-item {
      width: 100%;
      padding: 0 15px;
      margin: 0 auto 15px auto;
      display: block; }
      .form-item p {
        font-size: 14px;
        width: 100%;
        margin: 0 0 10px 0; }
      .form-item input,
      .form-item textarea {
        width: 100%;
        border: 1px solid #ccc; }
      .form-item textarea {
        padding: 10px;
        height: 200px; } }

.information-list,
.information-detail {
  width: 100%;
  max-width: 1072px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px; }
  @media screen and (max-width: 767px) {
    .information-list,
    .information-detail {
      padding: 0 15px;
      max-width: 100%; } }
  @media screen and (max-width: 767px) {
    .information-list,
    .information-detail {
      display: block;
      padding-bottom: 50px; } }

.information-list-archive {
  width: 250px;
  margin-right: 40px;
  font-size: 16px; }
  .information-list-archive h2 {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-bottom: 40px; }
  .information-list-archive ul {
    padding-left: 10px; }
    .information-list-archive ul li {
      margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .information-list-archive {
      width: 100%;
      margin-right: 0;
      font-size: 14px;
      margin: 0 0 30px 0; }
      .information-list-archive h2 {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 15px; }
      .information-list-archive ul li {
        margin-bottom: 10px; } }

.information-article {
  width: 100%;
  flex: 1; }
  .information-article h1 {
    line-height: 1.6; }
  .information-article h2 {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-bottom: 40px; }
  .information-article article {
    border-bottom: 1px dotted #000;
    padding: 25px 0 20px 10px; }
    .information-article article:first-of-type {
      padding-top: 0; }
  .information-detail .information-article h1 {
    font-size: 24px;
    border-bottom: 1px dotted #000;
    padding-bottom: 20px;
    margin-bottom: 40px; }
  @media screen and (max-width: 767px) {
    .information-article h2 {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 15px; }
    .information-article article {
      padding: 10px; }
      .information-article article:first-of-type {
        padding-top: 0; }
    .information-detail .information-article h1 {
      font-size: 21px;
      padding-bottom: 10px;
      margin-bottom: 20px; } }

.information-meta {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0; }
  @media screen and (max-width: 767px) {
    .information-meta {
      margin: 0 0 10px 0; } }

.information-date {
  font-size: 16px;
  margin-right: 15px; }
  @media screen and (max-width: 767px) {
    .information-date {
      font-size: 12px;
      margin-right: 5px; } }

.infornation-category {
  font-size: 16px;
  color: #fff;
  background: #000;
  padding: 0 10px;
  height: 23px;
  line-height: 23px;
  margin-right: 5px; }
  @media screen and (max-width: 767px) {
    .infornation-category {
      font-size: 12px;
      padding: 0 5px;
      margin-right: 5px;
      height: 15px;
      line-height: 15px; } }

.article-body {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 80px; }
  .article-body p {
    margin-bottom: 30px; }
    .article-body p:last-child {
      margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .article-body {
      font-size: 14px;
      margin-bottom: 40px; }
      .article-body p {
        margin-bottom: 20px; } }

.information-pager {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  font-size: 18px; }
  @media screen and (max-width: 767px) {
    .information-pager {
      font-size: 14px; } }

.shop-list {
  padding-bottom: 100px; }
  .shop-list h1 {
    width: 100%;
    max-width: 1072px;
    margin: 0 auto;
    font-size: 32px;
    margin-bottom: 30px;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .shop-list h1 {
        padding: 0 15px;
        max-width: 100%; } }
  .shop-list .shop-list-text {
    width: 100%;
    max-width: 1072px;
    margin: 0 auto;
    font-size: 16px;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 50px; }
    @media screen and (max-width: 767px) {
      .shop-list .shop-list-text {
        padding: 0 15px;
        max-width: 100%; } }
  .shop-list h2 {
    width: 100%;
    max-width: 1072px;
    margin: 0 auto;
    position: relative;
    font-size: 28px;
    line-height: 1;
    text-align: center;
    margin-bottom: 20px; }
    @media screen and (max-width: 767px) {
      .shop-list h2 {
        padding: 0 15px;
        max-width: 100%; } }
    .shop-list h2 span {
      position: relative;
      z-index: 1;
      background: #fff;
      padding: 0 15px; }
    .shop-list h2:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 1px;
      background: #000; }
  @media screen and (max-width: 767px) {
    .shop-list {
      padding-bottom: 50px; }
      .shop-list h1 {
        font-size: 21px; }
      .shop-list .shop-list-text {
        font-size: 14px;
        margin-bottom: 30px; }
      .shop-list h2 {
        width: calc(100% - 30px);
        margin: 0 auto 20px auto;
        font-size: 18px; } }

.shop-address {
  width: 100%;
  max-width: 1072px;
  margin: 0 auto;
  display: flex;
  margin-bottom: 120px; }
  @media screen and (max-width: 767px) {
    .shop-address {
      padding: 0 15px;
      max-width: 100%; } }
  @media screen and (max-width: 767px) {
    .shop-address {
      display: block;
      margin-bottom: 50px; } }

.shop-address-inner {
  width: 390px;
  padding-right: 15px; }
  .shop-address-inner img {
    max-width: 100%;
    margin-bottom: 15px; }
  .shop-address-inner h1 {
    font-size: 32px;
    margin-bottom: 30px; }
  .shop-address-inner p {
    font-size: 16px;
    line-height: 1.62;
    margin-bottom: 35px; }
  @media screen and (max-width: 767px) {
    .shop-address-inner {
      display: block;
      width: 100%;
      padding: 0; }
      .shop-address-inner h1 {
        font-size: 21px; }
      .shop-address-inner p {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 15px; } }

.shop-address-map {
  width: 100%;
  flex: 1;
  background: silver;
  height: 400px; }
  .shop-address-map iframe {
    width: 100%;
    height: 100%; }

.shop-list-text {
  width: 100%;
  max-width: 1072px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .shop-list-text {
      padding: 0 15px;
      max-width: 100%; } }

.shop-list-inner {
  width: 100%;
  max-width: 1072px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 50px; }
  @media screen and (max-width: 767px) {
    .shop-list-inner {
      padding: 0 15px;
      max-width: 100%; } }
  .shop-list-inner:last-child {
    margin-bottom: 0; }
  .shop-list-inner:after {
    content: "";
    display: block;
    width: calc((100% - 40px) / 3); }
  @media screen and (max-width: 767px) {
    .shop-list-inner:after {
      display: none; } }

.shop-list-item {
  padding: 20px;
  margin-bottom: 20px;
  background: #f1f1f1;
  width: calc((100% - 40px) / 3); }
  @media screen and (max-width: 767px) {
    .shop-list-item {
      width: 100%; } }

.shop-list-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .shop-list-title {
      font-size: 16px; } }

.shop-list-info {
  line-height: 1.87;
  font-size: 16px; }
  @media screen and (max-width: 767px) {
    .shop-list-info {
      font-size: 14px; } }

.top-concept {
  padding: 90px 0 100px;
  background-image: url(../img/top-copy-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .top-concept h1, .top-concept p {
    width: 100%;
    max-width: 1072px;
    margin: 0 auto;
    text-align: center;
    opacity: 0;
    transform: translate3d(0, 15px, 0);
    transition: all .7s ease; }
    @media screen and (max-width: 767px) {
      .top-concept h1, .top-concept p {
        padding: 0 15px;
        max-width: 100%; } }
    .top-concept h1.is-show, .top-concept p.is-show {
      transform: translate3d(0, 0, 0);
      opacity: 1; }
  .top-concept h1 {
    font-size: 36px;
    margin-bottom: 40px; }
  .top-concept p {
    font-size: 18px;
    line-height: 2.4;
    margin-bottom: 60px; }
    .top-concept p:last-child {
      margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .top-concept {
      padding: 50px 0;
      background-image: url(../img/top-copy-bg_sp.png); }
      .top-concept h1 {
        font-size: 21px;
        margin-bottom: 25px;
        line-height: 1.5; }
      .top-concept p {
        font-size: 14px;
        margin-bottom: 25px;
        text-align: left;
        line-height: 2; } }

.top-lineup {
  background: #f3f3f3;
  padding: 75px 0 30px 0;
  width: 100%;
  overflow: hidden; }
  .top-lineup h1 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px; }
  @media screen and (max-width: 767px) {
    .top-lineup {
      padding: 40px 0 20px 0; }
      .top-lineup h1 {
        font-size: 21px; } }

.top-lineup-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .top-lineup-inner {
      width: 100%;
      display: block; } }

.slider-for {
  width: calc(100% - 30px);
  max-width: 400px;
  text-align: center;
  background: #fff;
  font-size: 20px;
  padding: 15px;
  margin: 0 auto; }
  .slider-for h2 {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1.6; }
  .slider-for p {
    font-size: 14px;
    line-height: 1.6; }
  @media screen and (max-width: 767px) {
    .slider-for {
      margin: 0 15px 20px 15px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
      max-width: initial; }
      .slider-for:after {
        display: none; } }

.slider-caption {
  position: relative; }

.slider-nav {
  max-width: 800px;
  margin: 0 auto; }
  .slider-nav .slider-img {
    opacity: .6;
    filter: blur(2px);
    margin: 10px;
    padding: 10px;
    position: relative;
    text-align: center;
    transition: all .4s ease; }
    .slider-nav .slider-img img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      transition: all .4s ease; }
    @media screen and (max-width: 767px) {
      .slider-nav .slider-img {
        margin: 0;
        padding: 0; }
        .slider-nav .slider-img img {
          height: 250px; } }
  .slider-nav .slick-current .slider-img {
    opacity: 1;
    filter: none; }
    .slider-nav .slick-current .slider-img img {
      transform: scale(1.1); }

.slick-list {
  overflow: visible; }

.slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 5px; }
  .slick-dots li button {
    width: 10px;
    height: 10px;
    background: #ddd;
    border-radius: 50%; }
    .slick-dots li button:before {
      display: none; }
  .slick-dots li.slick-active button {
    background: #000; }

@media screen and (min-width: 767px) {
  .slick-slider .slick-track {
    transform: none !important; } }

.top-comment {
  position: relative;
  max-width: 1242px;
  margin: 0 auto;
  padding: 70px 0 50px 0; }
  .top-comment:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    max-width: calc(100% - 500px);
    height: 100%;
    background-image: url(../img/top-comment-pic.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  .top-comment img {
    display: none; }
  @media screen and (max-width: 767px) {
    .top-comment {
      width: 100%;
      max-width: 100%;
      padding: 45px 0 0 0; }
      .top-comment:after {
        display: none; }
      .top-comment img {
        width: 100%;
        display: block; } }

.top-comment-inner {
  width: 100%;
  max-width: 1072px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .top-comment-inner {
      padding: 0 15px;
      max-width: 100%; } }
  .top-comment-inner h1, .top-comment-inner p {
    width: 50%;
    min-width: 500px;
    margin-bottom: 30px; }
  .top-comment-inner h1 {
    font-size: 28px; }
    .top-comment-inner h1:before, .top-comment-inner h1:after {
      content: "";
      display: inline-block;
      width: 30px;
      height: 24px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: 0 0;
      position: relative;
      top: -10px; }
    .top-comment-inner h1:before {
      background-image: url(../img/blockquote-open.png);
      margin-right: 5px; }
    .top-comment-inner h1:after {
      background-image: url(../img/blockquote-close.png);
      margin-left: 5px; }
  .top-comment-inner p {
    font-size: 16px;
    line-height: 1.3;
    padding: 0 50px 0 0; }
  @media screen and (max-width: 767px) {
    .top-comment-inner h1, .top-comment-inner p {
      width: 100%;
      min-width: 0;
      padding: 0; }
    .top-comment-inner h1 {
      font-size: 21px;
      margin-bottom: 25px; }
      .top-comment-inner h1:before, .top-comment-inner h1:after {
        width: 15px;
        height: 12px; }
      .top-comment-inner h1:before {
        margin-right: 10px; }
      .top-comment-inner h1:after {
        margin-left: 10px; }
    .top-comment-inner p {
      font-size: 14px;
      line-height: 1.5; } }

.top-comment-name {
  text-align: right;
  padding: 0;
  font-size: 18px; }
  .top-comment-name span {
    font-size: 24px; }
  @media screen and (max-width: 767px) {
    .top-comment-name {
      font-size: 11px; }
      .top-comment-name span {
        font-size: 16px; } }

.top-shop {
  padding: 80px 0 90px 0;
  background-image: url(../img/top-shop-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .top-shop h1, .top-shop p {
    width: 100%;
    max-width: 1072px;
    margin: 0 auto;
    color: #fff;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .top-shop h1, .top-shop p {
        padding: 0 15px;
        max-width: 100%; } }
  .top-shop h1 {
    font-size: 36px;
    margin-bottom: 30px; }
  .top-shop p {
    line-height: 1.55;
    font-size: 18px;
    margin-bottom: 50px; }
    .top-shop p:last-child {
      margin-bottom: 0; }
  @media screen and (max-width: 767px) {
    .top-shop {
      background-image: url(../img/top-shop-bg_sp.png);
      padding: 50px 0 60px 0; }
      .top-shop h1 {
        font-size: 21px;
        margin-bottom: 30px; }
      .top-shop p:not(.btn_border):not(.btn):not(.btn_border) {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 40px;
        text-align: left; } }

.top-about {
  padding: 80px 0 90px 0;
  background-image: url(../img/top-about-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .top-about h1, .top-about p {
    width: 100%;
    max-width: 1072px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .top-about h1, .top-about p {
        padding: 0 15px;
        max-width: 100%; } }
  .top-about h1 {
    font-size: 36px; }
  .top-about p {
    font-size: 18px;
    line-height: 1.55; }
  @media screen and (max-width: 767px) {
    .top-about {
      padding: 50px 0 60px 0; }
      .top-about h1 {
        font-size: 21px;
        margin-bottom: 25px; }
      .top-about p:not(.btn_border):not(.btn):not(.btn_border) {
        font-size: 14px;
        line-height: 1.5;
        text-align: left; } }

.top-about-pics {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .top-about-pics span {
    margin: 0 25px;
    width: 250px;
    height: 250px;
    line-height: 0; }
    .top-about-pics span img {
      max-width: 100%; }
  @media screen and (max-width: 767px) {
    .top-about-pics {
      margin-top: 40px;
      justify-content: space-between; }
      .top-about-pics span {
        width: calc((100% - 4px) / 3);
        height: auto;
        margin: 0; }
        .top-about-pics span img {
          width: 100%;
          max-width: initial; } }

.top-contact {
  padding: 80px 0 90px 0; }
  .top-contact h1 {
    width: 100%;
    max-width: 1072px;
    margin: 0 auto;
    text-align: center;
    font-size: 38px;
    margin-bottom: 40px; }
    @media screen and (max-width: 767px) {
      .top-contact h1 {
        padding: 0 15px;
        max-width: 100%; } }
  .top-contact p span {
    color: #666;
    font-size: 15px; }
  @media screen and (max-width: 767px) {
    .top-contact {
      padding: 40px 0 60px 0; }
      .top-contact h1 {
        font-size: 21px;
        margin-bottom: 25px; }
      .top-contact p span {
        font-size: 10px; } }

.tel_box {
  display: flex;
  justify-content: center; }
  @media screen and (max-width: 767px) {
    .tel_box {
      display: block;
      padding: 40px 0 20px 0; } }

.icon-tel {
  position: relative;
  font-size: 30px;
  margin: 15px 10px 30px 10px; }
  .icon-tel:before {
    content: "";
    display: inline-block;
    width: 26px;
    height: 31px;
    background-image: url(../img/icon-tel.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0 0;
    margin: 0 10px 0 0;
    position: relative;
    top: 5px; }
  .icon-tel small {
    position: absolute;
    top: -15px;
    left: 30px; }
  @media screen and (max-width: 767px) {
    .icon-tel {
      font-size: 25px;
      margin-bottom: 10px;
      margin-top: 40px; }
      .icon-tel:first-child {
        margin-top: 0; }
      .icon-tel small {
        left: 0;
        right: 0;
        text-align: center; }
      .icon-tel:before {
        width: 18px;
        height: 22px;
        top: 0; } }

.top-contact-inner {
  width: 100%;
  max-width: 1072px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 767px) {
    .top-contact-inner {
      padding: 0 15px;
      max-width: 100%; } }
  @media screen and (max-width: 767px) {
    .top-contact-inner {
      display: block; } }

.top-contact-box {
  width: calc(50% - 10px);
  margin: 0 10px 0 0;
  padding: 40px 0;
  border: 1px solid #000;
  text-align: center; }
  .top-contact-box:last-child {
    margin: 0 0 0 10px; }
  .top-contact-box h2 {
    font-size: 26px;
    margin-bottom: 30px; }
  .contact .top-contact-box {
    width: 100%;
    max-width: 1072px;
    margin: 0 auto;
    max-width: 932px;
    margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .contact .top-contact-box {
        padding: 0 15px;
        max-width: 100%; } }
  @media screen and (max-width: 767px) {
    .top-contact-box {
      margin: 0;
      width: 100%;
      margin-bottom: 10px;
      padding: 25px 0; }
      .top-contact-box:last-child {
        margin: 0; }
      .top-contact-box h2 {
        font-size: 17px;
        margin-bottom: 10px; }
      .contact .top-contact-box {
        width: calc(100% - 30px);
        margin: 0 auto;
        padding: 20px; } }

.top-information {
  background-image: url(../img/top-info-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 90px 0 90px 0; }
  .top-information h1 {
    width: 100%;
    max-width: 1072px;
    margin: 0 auto;
    text-align: center;
    font-size: 36px;
    margin-bottom: 40px; }
    @media screen and (max-width: 767px) {
      .top-information h1 {
        padding: 0 15px;
        max-width: 100%; } }
  @media screen and (max-width: 767px) {
    .top-information {
      padding: 50px 0 60px 0; }
      .top-information h1 {
        font-size: 21px;
        margin-bottom: 30px; } }

.top-information-inner {
  width: 100%;
  max-width: 1072px;
  margin: 0 auto;
  padding: 50px 100px;
  background: #fff; }
  @media screen and (max-width: 767px) {
    .top-information-inner {
      padding: 0 15px;
      max-width: 100%; } }
  .top-information-inner dl {
    display: flex;
    border-bottom: 1px dashed #000;
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;
    line-height: 1.5; }
    .top-information-inner dl:last-child {
      border: none;
      margin: 0;
      padding: 0; }
    .top-information-inner dl dt {
      width: 145px;
      padding-left: 20px; }
    .top-information-inner dl dd {
      flex: 1;
      width: 100%; }
  @media screen and (max-width: 767px) {
    .top-information-inner {
      width: calc(100% - 30px);
      padding: 15px; }
      .top-information-inner dl {
        display: block; }
        .top-information-inner dl dt {
          width: 100%;
          padding: 0; } }

@media screen and (min-width: 767px) {
  .pc-hide {
    display: none; } }

@media screen and (max-width: 767px) {
  .sp-hide {
    display: none !important; } }

.mb50 {
  margin-bottom: 50px !important; }
  @media screen and (max-width: 767px) {
    .mb50 {
      margin-bottom: 25px !important; } }

.mb35 {
  margin-bottom: 35px !important; }
  @media screen and (max-width: 767px) {
    .mb35 {
      margin-bottom: 20px !important; } }

.txtC {
  text-align: center !important; }

.ma {
  margin-left: auto !important;
  margin-right: auto !important; }
