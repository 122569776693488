.l-footer {
  background: #000;
  color: #fff;
  padding: 25px 0 60px 0;
  @include mqSP {
    padding: 25px 0;
  }
}

.footer-logo {
  @include texthidden;
  width: 67px;
  height: 80px;
  a {
    display: block;
    width: 67px;
    height: 80px;
    background-image: url(../img/logo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
  }
  @include mqSP {
    position: absolute;
    left: 15px;
    top: 0;
    width: 34px;
    height: 40px;
    a {
      width: 34px;
      height: 40px;
    }

  }
}
.footer-inner {
  @include container;
  position: relative;
  display: flex;
  @include mqSP {
    display: block;
    padding: 0;
  }
}
.footer-sp-inner {
  display: flex;
  @include mqSP {
    display: block;
    margin: 0 0 0 60px;
  }
}

.footer-name {
  font-size: 22px;
  margin: 10px 30px 0 30px;
  @include mqSP {
    margin: 0 0 10px 0;
    font-size: 16px;
  }
}
.footer-address {
  font-size: 16px;
  line-height: 1.37;
  margin: 10px 0 0 0;
  @include mqSP {
    font-size: 12px;
  }
}
.footer-copy {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-size: 14px;
  text-align: right;
  @include mqSP {
    position: relative;
    text-align: center;
    border-top: 1px solid #fff;
    font-size: 9px;
    padding: 15px 0;
  }
}