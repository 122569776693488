.btn {
  position: relative;
  a,span {
    position: relative;
    display: inline-block;
    color: #fff;
    width: 280px;
    height: 54px;
    line-height: 52px;
    font-size: 18px;
    background: #000;
    text-align: center;
    padding-right: 10px;
    &:after {
      content: "";
      position: absolute;
      right: 20px;
      top: 0;
      width: 9px;
      height: 100%;
      background: url(../img/arrow.png);
      background-size: 9px 12px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .top-about & {
    margin-top: 50px;
  }
  .top-information & {
    margin-top: 40px;
    text-align: center;
  }
  .l-footer & {
    position: absolute;
    right: 0;
    top: 0;
    a {
      font-size: 16px;
      width: 220px;
      height: 48px;
      line-height: 46px;
    }
  }
  @include mqSP {
    a,span {
      font-size: 12px;
      width: 180px;
      height: 38px;
      line-height: 36px;
    }
    .top-about & {
      margin-top: 40px;
    }
    .l-footer & {
      position: relative;
      text-align: center;
      margin: 25px 0 35px 0;
      a {
        font-size: 12px;
        width: 180px;
        height: 38px;
        line-height: 36px;
      }

    }

  }
}
.btn_border {
  @extend .btn;
  a {
    border: 1px solid #fff;
  }
}