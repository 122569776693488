@charset "UTF-8";
//-------------------------------------------------------------
// Variable
//-------------------------------------------------------------
$max-width: 1072px;
@mixin container() {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  @include mqSP {
    padding: 0 15px;
    max-width: 100%;
  }
}

//-------------------------
// color
//-------------------------


//-------------------------
// font-family
//-------------------------
$f_go: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Arial, Helvetica, sans-serif;
$f_min: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;

// //-------------------------
// // breakpoint
// //-------------------------
// $breakpoint-sp: 640px;