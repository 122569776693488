.notfound {
  @include container;
  padding-bottom: 100px;
  h1 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 30px;
  }
  p {
    font-size: 16px;
    text-align: center;
    margin-bottom: 40px;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }

  }
  @include mqSP {
    padding: 0 15px 50px 15px;
    h1 {
      font-size: 21px;
    }
    p {
    }
  }
}