.shop-list {
  padding-bottom: 100px;
  h1 {
    @include container;
    font-size: 32px;
    margin-bottom: 30px;
    text-align: center;
  }
  .shop-list-text {
    @include container;
    font-size: 16px;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 50px;
  }
  h2 {
    @include container;
    position: relative;
    font-size: 28px;
    line-height: 1;
    text-align: center;
    margin-bottom: 20px;
    span {
      position: relative;
      z-index: 1;
      background: #fff;
      padding: 0 15px;
    }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 1px;
      background: #000;
    }
  }
  @include mqSP {
    padding-bottom: 50px;
    h1 {
      font-size: 21px;
    }
    .shop-list-text {
      font-size: 14px;
      margin-bottom: 30px;
    }
    h2 {
      width: calc(100% - 30px);
      margin: 0 auto 20px auto;
      font-size: 18px;
    }
  }
}
.shop-address {
  @include container;
  display: flex;
  margin-bottom: 120px;
  @include mqSP {
    display: block;
    margin-bottom: 50px;
  }
}
.shop-address-inner {
  width: 390px;
  padding-right: 15px;
  img {
    max-width: 100%;
    margin-bottom: 15px;
  }
  h1 {
    font-size: 32px;
    margin-bottom: 30px;
  }
  p {
    font-size: 16px;
    line-height: 1.62;
    margin-bottom: 35px;
  }
  @include mqSP {
    display: block;
    width: 100%;
    padding: 0;
    h1 {
      font-size: 21px;
    }
    p {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 15px;
    }
  }
}
.shop-address-map {
  width: 100%;
  flex: 1;
  background: silver;
  height: 400px;
  iframe {
    width: 100%;
    height: 100%;
  }
}


.shop-list-text {
  @include container;
}
.shop-list-inner {
  @include container;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0;
  }
  &:after {
    content: "";
    display: block;
    width: calc((100% - 40px) / 3);
  }
  @include mqSP {
    &:after {
      display: none;
    }
  }

}
.shop-list-item {
  padding: 20px;
  margin-bottom: 20px;
  background: #f1f1f1;
  width: calc((100% - 40px) / 3);
  @include mqSP {
    width: 100%;
  }
}
.shop-list-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  @include mqSP {
    font-size: 16px;
  }
}
.shop-list-info {
  line-height: 1.87;
  font-size: 16px;
  @include mqSP {
    font-size: 14px;
  }
}
