.top-concept {
  padding: 90px 0 100px;
  background-image: url(../img/top-copy-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  h1,p {
    @include container;
    text-align: center;
    opacity: 0;
    transform: translate3d(0,15px,0);
    transition: all .7s ease;
    &.is-show {
      transform: translate3d(0,0,0);
      opacity: 1;
    }
  }
  h1 {
    font-size: 36px;
    margin-bottom: 40px;
  }
  p {
    font-size: 18px;
    line-height: 2.4;
    margin-bottom: 60px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include mqSP {
    padding: 50px 0;
    background-image: url(../img/top-copy-bg_sp.png);
    h1 {
      font-size: 21px;
      margin-bottom: 25px;
      line-height: 1.5;
    }
    p {
      font-size: 14px;
      margin-bottom: 25px;
      text-align: left;
      line-height: 2;
    }
  }
}

.top-lineup {
  background: #f3f3f3;
  padding: 75px 0 30px 0;
  width: 100%;
  overflow: hidden;
  h1 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
  }
  @include mqSP {
    padding: 40px 0 20px 0;
    h1 {
      font-size: 21px;
    }
  }
}
.top-lineup-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  margin: 0 auto;
  @include mqSP {
    width: 100%;
    display: block;
  }
}
.slider-for {
  width: calc(100% - 30px);
  max-width: 400px;
  text-align: center;
  background: #fff;
  font-size: 20px;
  padding: 15px;
  margin: 0 auto;
  // box-shadow: 0px 0px 6px rgba(0,0,0,.2);
  h2 {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1.6;
  }
  p {
    font-size: 14px;
    line-height: 1.6;
  }
  @include mqSP {
    margin: 0 15px 20px 15px;
    box-shadow: 0px 0px 6px rgba(0,0,0,.2);
    max-width: initial;
    &:after {
      display: none;
    }
  }
}
.slider-caption {
  position: relative;
  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: -35px;
  //   left: 0;
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   border-width: 0 8px 20px 8px;
  //   // box-shadow: 0px 0px 6px rgba(0,0,0,.2);
  //   border-color: transparent transparent #ffffff transparent;
  // }
  // .slick-slide:nth-child(1) & {
  //   &:after {
  //     left: 85px;
  //   }
  // }
  // .slick-slide:nth-child(2) & {
  //   &:after {
  //     left: 205px;
  //   }
  // }
  // .slick-slide:nth-child(3) & {
  //   &:after {
  //     left: 320px;
  //   }
  // }
  // .slick-slide:nth-child(4) & {
  //   &:after {
  //     left: 435px;
  //   }
  // }
  // .slick-slide:nth-child(5) & {
  //   &:after {
  //     left: 555px;
  //   }
  // }
  // .slick-slide:nth-child(6) & {
  //   &:after {
  //     left: 675px;
  //   }
  // }
  // @include mqSP {
  //   &:after {
  //     display: none;
  //   }
  // }
}
.slider-nav {
  max-width: 800px;
  margin: 0 auto;
  .slider-img {
    opacity: .6;
    filter: blur(2px);
    margin: 10px;
    padding: 10px;
    position: relative;
    text-align: center;
    transition: all .4s ease;
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      // height: 200px;
      transition: all .4s ease;
    }
    @include mqSP {
      margin: 0;
      padding: 0;
      img {
        height: 250px;
      }
    }
  }
  .slick-current {
    .slider-img {
      opacity: 1;
      filter: none;
      img {
        transform: scale(1.1);
      }
    }
  }
}
.slick-list {
  overflow: visible;
}
.slick-dots li {
    width: 10px;
    height: 10px;
  margin: 0 5px;
  button {
    width: 10px;
    height: 10px;
    background: #ddd;
    border-radius: 50%;
    &:before {
      display: none;
    }
  }
  &.slick-active {
    button {
      background: #000;
    }
  }
}
.slick-slider .slick-track {
  @media screen and (min-width: 767px) {
    transform: none !important;
  }
}


.top-comment {
  position: relative;
  max-width: 1242px;
  margin: 0 auto;
  padding: 70px 0 50px 0;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    max-width: calc(100% - 500px);
    height: 100%;
    background-image: url(../img/top-comment-pic.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  img {
    display: none;
  }
  @include mqSP {
    width: 100%;
    max-width: 100%;
    padding: 45px 0 0 0;
    &:after {
      display: none;
    }
    img {
      width: 100%;
      display: block;
    }
  }
}
.top-comment-inner {
  @include container;
  h1,p {
    width: 50%;
    min-width: 500px;
    margin-bottom: 30px;
  }
  h1 {
    font-size: 28px;
    &:before,
    &:after {
      content: "";
      display: inline-block;
      width: 30px;
      height: 24px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: 0 0;
      position: relative;
      top: -10px;
    }
    &:before {
      background-image: url(../img/blockquote-open.png);
      margin-right: 5px;
    }
    &:after {
      background-image: url(../img/blockquote-close.png);
      margin-left: 5px;
    }
  }
  p {
    font-size: 16px;
    line-height: 1.3;
    padding: 0 50px 0 0;
  }
  @include mqSP {
    h1, p {
      width: 100%;
      min-width: 0;
      padding: 0;
    }
    h1 {
      font-size: 21px;
      margin-bottom: 25px;
      &:before,
      &:after {
        width: 15px;
        height: 12px;
      }
      &:before {
        margin-right: 10px;
      }
      &:after {
        margin-left: 10px;
      }

    }
    p {
      font-size: 14px;
      line-height: 1.5;
    }
  }
}
.top-comment-name {
  text-align: right;
  padding: 0;
  font-size: 18px;
  span {
    font-size: 24px;
  }
  @include mqSP {
    font-size: 11px;
    span {
      font-size: 16px;
    }
  }
}

.top-shop {
  padding: 80px 0 90px 0;
  background-image: url(../img/top-shop-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  h1, p {
    @include container;
    color: #fff;
    text-align: center;
  }
  h1 {
    font-size: 36px;
    margin-bottom: 30px;
  }
  p {
    line-height: 1.55;
    font-size: 18px;
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include mqSP {
    background-image: url(../img/top-shop-bg_sp.png);
    padding: 50px 0 60px 0;
    h1 {
      font-size: 21px;
      margin-bottom: 30px;
    }
    p:not(.btn_border):not(.btn) {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 40px;
      text-align: left;
    }
  }
}

.top-about {
  padding: 80px 0 90px 0;
  background-image: url(../img/top-about-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  h1, p {
    @include container;
    text-align: center;
    margin-bottom: 30px;
  }
  h1 {
    font-size: 36px;
  }
  p {
    font-size: 18px;
    line-height: 1.55;
  }
  @include mqSP {
    padding: 50px 0 60px 0;
    h1 {
      font-size: 21px;
      margin-bottom: 25px;
    }
    p:not(.btn_border):not(.btn) {
      font-size: 14px;
      line-height: 1.5;
      text-align: left;
    }
  }
}
.top-about-pics {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin: 0 25px;
    width: 250px;
    height: 250px;
    line-height: 0;
    img {
      max-width: 100%;
    }
  }
  @include mqSP {
    margin-top: 40px;
    justify-content: space-between;
    span {
      width: calc((100% - 4px) / 3);
      height: auto;
      margin: 0;
      img {
        width: 100%;
        max-width: initial;
      }
    }
  }
}


.top-contact {
  padding: 80px 0 90px 0;
  h1 {
    @include container;
    text-align: center;
    font-size: 38px;
    margin-bottom: 40px;
  }
  p span {
    color: #666;
    font-size: 15px;
  }
  @include mqSP {
    padding: 40px 0 60px 0;
    h1 {
      font-size: 21px;
      margin-bottom: 25px;
    }
    p span {
      font-size: 10px;
    }
  }
}
.tel_box {
  display: flex;
  justify-content: center;
  @include mqSP {
    display: block;
    padding: 40px 0 20px 0;
  }
}
.icon-tel {
  position: relative;
  font-size: 30px;
  margin: 15px 10px 30px 10px;
  &:before {
    content: "";
    display: inline-block;
    width: 26px;
    height: 31px;
    background-image: url(../img/icon-tel.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0 0;
    margin: 0 10px 0 0;
    position: relative;
    top: 5px;
  }
  small {
    position: absolute;
    top: -15px;
    left: 30px;
  }
  @include mqSP {
    font-size: 25px;
    margin-bottom: 10px;
    margin-top: 40px;
    &:first-child {
      margin-top: 0;
    }
    small {
      left: 0;
      right: 0;
      text-align: center;
    }
    &:before {
      width: 18px;
      height: 22px;
      top: 0;
    }
  }
}

.top-contact-inner {
  @include container;
  display: flex;
  justify-content: space-between;
  @include mqSP {
    display: block;
  }
}
.top-contact-box {
  width: calc(50% - 10px);
  margin: 0 10px 0 0;
  padding: 40px 0;
  border: 1px solid #000;
  text-align: center;
  &:last-child {
    margin: 0 0 0 10px;
  }
  h2 {
    font-size: 26px;
    margin-bottom: 30px;
  }
  .contact & {
    @include container;
    max-width: 932px;
    margin: 0 auto;
  }
  @include mqSP {
    margin: 0;
    width: 100%;
    margin-bottom: 10px;
    padding: 25px 0;
    &:last-child {
      margin: 0;
    }
    h2 {
      font-size: 17px;
      margin-bottom: 10px;
    }
    .contact & {
      width: calc(100% - 30px);
      margin: 0 auto;
      padding: 20px;
    }
  }
}

.top-information {
  background-image: url(../img/top-info-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 90px 0 90px 0;
  h1 {
    @include container;
    text-align: center;
    font-size: 36px;
    margin-bottom: 40px;
  }
  @include mqSP {
    padding: 50px 0 60px 0;
    h1 {
      font-size: 21px;
      margin-bottom: 30px;
    }
  }
}
.top-information-inner {
  @include container;
  padding: 50px 100px;
  background: #fff;
  dl {
    display: flex;
    border-bottom: 1px dashed #000;
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;
    line-height: 1.5;
    &:last-child {
      border: none;
      margin: 0;
      padding: 0;
    }
    dt {
      width: 145px;
      padding-left: 20px;
    }
    dd {
      flex: 1;
      width: 100%;
    }
  }
  @include mqSP {
    width: calc(100% - 30px);
    padding: 15px;
    dl {
      display: block;
      dt {
        width: 100%;
        padding: 0;
      }
    }
  }

}


