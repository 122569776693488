.information-list,
.information-detail {
  @include container;
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
  @include mqSP {
    display: block;
    padding-bottom: 50px;
  }
}
.information-list-archive {
  width: 250px;
  margin-right: 40px;
  font-size: 16px;

  h2 {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-bottom: 40px;
  }
  ul {
    padding-left: 10px;
    li {
      margin-bottom: 10px;
    }
  }
  @include mqSP {
    width: 100%;
    margin-right: 0;
    font-size: 14px;
    margin: 0 0 30px 0;
    h2 { 
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 15px;
    }
    ul {
      li {
        margin-bottom: 10px;
      }
    }
  }
}
.information-article {
  width: 100%;
  flex: 1;
  h1 {
    line-height: 1.6;
  }
  h2 {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-bottom: 40px;
  }
  article {
    border-bottom: 1px dotted #000;
    padding: 25px 0 20px 10px;
    &:first-of-type {
      padding-top: 0;
    }
  }
  .information-detail & {
    h1 {
      font-size: 24px;
      border-bottom: 1px dotted #000;
      padding-bottom: 20px;
      margin-bottom: 40px;
    }
  }
  @include mqSP {
    h2 { 
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 15px;
    }
    article {
      padding: 10px;
      &:first-of-type {
        padding-top: 0;
      }
    }
    .information-detail & {
      h1 {
        font-size: 21px;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
    }
  }
}
.information-meta {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
  @include mqSP {
    margin: 0 0 10px 0;
  }
}
.information-date {
  font-size: 16px;
  margin-right: 15px;
  @include mqSP {
    font-size: 12px;
    margin-right: 5px;
  }
}
.infornation-category {
  font-size: 16px;
  color: #fff;
  background: #000;
  padding: 0 10px;
  height: 23px;
  line-height: 23px;
  margin-right: 5px;
  @include mqSP {
    font-size: 12px;
    padding: 0 5px;
    margin-right: 5px;
    height: 15px;
    line-height: 15px;
  }
}
.article-body {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 80px;
  p {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include mqSP {
    font-size: 14px;
    margin-bottom: 40px;
    p {
      margin-bottom: 20px;
    }
  }
}
.information-pager {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  font-size: 18px;
  @include mqSP {
    font-size: 14px;
  }
}